import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Grid from './form/Grid';
import { Draggable } from 'react-beautiful-dnd';
import DeleteIcon from './icon/DeleteIcon';
import AudioSampler from './AudioSampler';
import Checkbox from './form/Checkbox';
import TextField from './form/TextField';

class SongDragger extends React.Component {
    state = {};

    render() {
        const { classes, form, listType, disabled } = this.props;
        const o = listType;
        return (
            <div ref={this.props.provided.innerRef} {...this.props.provided.droppableProps}>
                {!(form.getField(o.list) && form.getField(o.list).length) ? (
                    <Grid pc={1}>No songs selected.</Grid>
                ) : (
                    form
                        .getField(o.list)
                        .sort((a, b) => (a.SongSort < b.SongSort ? -1 : 1))
                        .map((e, i, a) => (
                            <Draggable key={i} draggableId={o.type + i} index={i}>
                                {(provided, snapshot) => (
                                    <div
                                        className={cx(classes.listItem, snapshot.isDragging && classes.dragging)}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        <div className={classes.mainInfo}>
                                            <span>{i + 1}. </span>
                                            <AudioSampler title={e.Name} artist={e.Artist} filename={e.Pathname} />

                                            {!disabled && (
                                                <span
                                                    style={{ float: 'right' }}
                                                    {...provided.dragHandleProps}
                                                    title="Reorder"
                                                    tabIndex="0"
                                                >
                                                    <IconButton style={{ pointerEvents: 'none' }} tabIndex="-1">
                                                        <Icon>drag_indicator</Icon>
                                                    </IconButton>
                                                </span>
                                            )}
                                            <span>
                                                <IconButton
                                                    disabled={disabled}
                                                    title="Remove"
                                                    onClick={() => {
                                                        const arr = form.getField(o.list);
                                                        arr.splice(i, 1);
                                                        form.setField({
                                                            [o.list]: arr
                                                        });
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </span>
                                        </div>
                                        {o.list === 'ReflectionMusicSongs' && (
                                            <div className={classes.extraStuff}>
                                                <Checkbox
                                                    disabled={disabled}
                                                    name={'ReflectionMusicSongs[i].IncludeInLifeStory'}
                                                    label={'Song to be played during life story'}
                                                    checked={
                                                        form.getField('ReflectionMusicSongs')[i].IncludeInLifeStory ||
                                                        false
                                                    }
                                                    onChange={e => {
                                                        const ReflectionMusicSongs = form.getField(
                                                            'ReflectionMusicSongs'
                                                        );
                                                        ReflectionMusicSongs[i].IncludeInLifeStory =
                                                            e.target.checked || false;
                                                        form.setField({
                                                            ReflectionMusicSongs
                                                        });
                                                    }}
                                                />
                                                <br />
                                                <Checkbox
                                                    disabled={disabled}
                                                    name={'ReflectionMusicSongs[i].ForFlowerPlacement'}
                                                    label={'Song to be played during flowers'}
                                                    checked={
                                                        form.getField('ReflectionMusicSongs')[i].ForFlowerPlacement
                                                    }
                                                    onChange={e => {
                                                        const ReflectionMusicSongs = form.getField(
                                                            'ReflectionMusicSongs'
                                                        );
                                                        ReflectionMusicSongs[i].ForFlowerPlacement =
                                                            e.target.checked || false;
                                                        form.setField({
                                                            ReflectionMusicSongs
                                                        });
                                                    }}
                                                />
                                                <br />
                                                <TextField
                                                    name={'ReflectionMusicSongs[i].OtherText'}
                                                    label={'Other details'}
                                                    value={form.getField('ReflectionMusicSongs')[i].OtherText || ''}
                                                    onChange={e => {
                                                        const ReflectionMusicSongs = form.getField(
                                                            'ReflectionMusicSongs'
                                                        );
                                                        ReflectionMusicSongs[i].OtherText = e.target.value || null;
                                                        form.setField({
                                                            ReflectionMusicSongs
                                                        });
                                                    }}
                                                    style={{ marginBottom: '1rem' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Draggable>
                        ))
                )}
                {this.props.provided.placeholder}
            </div>
        );
    }
}

const styles = ({ palette, funeralHome, typography, sizes }) => ({
    listItem: {
        width: '100%',
        padding: '0 6px',
        margin: '6px 6px 0',
        borderRadius: 5, // backgroundColor: palette.contentBackground[funeralHome],
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.035)'
        },
        '& > span': {
            display: 'inline-block',
            fontSize: '0.875rem',
            verticalAlign: 'middle'
        },
        '& > p': {
            margin: '0.65rem 0'
        }
    },
    mainInfo: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    extraStuff: {
        marginLeft: 48
    },
    dragging: {
        backgroundColor: '#ccc' //palette.contentBackground[funeralHome]
    }
});

export default withRouter(withStyles(styles)(SongDragger));
