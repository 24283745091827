import React from 'react';
import { isNullOrUndefined } from './objects';
import { CommittalIcon, FireIcon } from '../component/IconIndex';

/**
 * Escapes user entered text for use as a literal string within a regular expression.
 *
 * @param text Text which may include characters requiring escaping.
 */
export function sanitizeForRegExp(text = '') {
    // $& means the whole matched string
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Join only the defined strings
 */
export function joinDefined(strings, separator = '') {
    return strings
        .filter(s => !!s)
        .map(e => ('' + e).trim())
        .join(separator);
}

/**
 * Replaces the parameters (e.g. /funeral/:id) in the url with the provided param values
 */
export function applyUrlParams(path, params) {
    return Object.keys(params).reduce((acc, key) => !!acc && acc.replace(`:${key}`, params[key]), path);
}

export function callPhone(string) {
    let phone = string || '';
    phone = phone.replace(/[abc]/gi, '2');
    phone = phone.replace(/[def]/gi, '3');
    phone = phone.replace(/[ghi]/gi, '4');
    phone = phone.replace(/[jkl]/gi, '5');
    phone = phone.replace(/[mno]/gi, '6');
    phone = phone.replace(/[pqrs]/gi, '7');
    phone = phone.replace(/[tuv]/gi, '8');
    phone = phone.replace(/[wxyz]/gi, '9');
    phone = phone.replace(/[^#*\d]/gi, '');
    return phone;
}

/**
 * pads a string by x zeros
 */
export function pad(str, padding, character = '0') {
    if (typeof myVar !== 'string') str += '';
    while (str.length < padding) str = character + str;
    return str;
}

/**
 * Converts a string to Title Case
 * ...yes, the irony of this function name being in camelCase
 * but they don't actually want Title Case, JuSt CaptAlise The FirSt Letter.
 */
export function titleCase(str) {
    if (!str) return '';
    return str
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1) /*.toLowerCase()*/)
        .join(' ');
}

export function prettyPrice(val, raw = false) {
    if (isNullOrUndefined(val)) return null;
    const bits = (1 * val).toFixed(2).split('.');
    const bobs = bits[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (!!raw) return Number(bobs.replace(/\D/g, '') + '.' + bits[1]);
    return (
        <span style={{ whiteSpace: 'pre' }}>
            <small>$</small>
            {bobs}
            <small>.{bits[1]}</small>
        </span>
    );
}

export function prettyNumber(val, raw = false) {
    if (isNullOrUndefined(val)) return null;
    const bobs = val.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (!!raw) return bobs;
    return <span>{bobs}</span>;
}

export function prettyAddressBook(val, raw = false) {
    if (isNullOrUndefined(val)) return null;
    const bobs = [val['Name'], val['AddressLine1'], val['AddressLine2'], val['Suburb'], val['State'], val['Postcode']];
    if (!!raw) return joinDefined(bobs, ', ');
    return (
        <span>
            <strong>{bobs[0]}</strong>: {joinDefined([bobs[1], bobs[2]], ', ')}, {bobs[3]} {bobs[4]}
        </span>
    );
}

export function prettyEmail(val, hideLink = true, raw = false) {
    if (!val) return null;
    const bits = val.toString().split(/[;,]+/);
    return bits.map((bobs, i) => {
        bobs = bobs.trim();
        if (!!raw) return bobs;
        return (
            <span key={i}>
                {i !== 0 && <br />}
                <a style={!!hideLink ? { color: 'inherit', textDecoration: 'none' } : {}} href={'mailto:' + bobs}>
                    {bobs}
                </a>
            </span>
        );
    });
}

export function prettyPhone(val, hideLink = true, raw = false) {
    if (!val) return null;
    const bits = val.toString().split('/');
    const result = bits.map((bobs, i) => {
        bobs = bobs.trim();
        bobs = bobs.replace(/(\d)-(\d)/g, '$1 $2');
        bobs = bobs.replace(/(\d)\s+?(\d)/g, '$1$2');
        bobs = bobs.replace(/^04(\d{2})(\d{3})(\d{3})/g, '04$1 $2 $3');
        bobs = bobs.replace(/^1800(\d{3})(\d{3})/g, '1800 $1 $2');
        bobs = bobs.replace(/^(0\d)(\d{4})(\d{4})/g, '($1) $2 $3');
        bobs = bobs.replace(/(\d{4})(\d{4})/g, '$1 $2');
        bobs = bobs.replace(/^(\d{4})\s?(\d{4})$/g, '(02) $1 $2');
        if (!!raw) return bobs;
        return (
            <span key={i}>
                {i !== 0 && <br />}
                <a
                    style={!!hideLink ? { color: 'inherit', textDecoration: 'none' } : {}}
                    href={'tel:' + callPhone(bobs)}
                >
                    {bobs}
                </a>
            </span>
        );
    });
    if (!!raw && Array.isArray(result)) return result.join(' / ');
    return result;
}

export function prettyDisposal(title, lnBrk = true, raw = false) {
    let result = '';
    const Tag = lnBrk ? 'small' : 'span';
    switch (title) {
        case 'Buried':
            result = raw ? (
                'Burial'
            ) : (
                <Tag>
                    <CommittalIcon title={'Burial'} />
                    {lnBrk ? <br /> : ' '}Burial
                </Tag>
            );
            break;
        case 'Cremated':
            result = raw ? (
                'Cremation'
            ) : (
                <Tag>
                    <FireIcon title={'Cremation'} />
                    {lnBrk ? <br /> : ' '}Cremation
                </Tag>
            );
            break;
        case 'Repatriated':
            result = 'Repatriation';
            break;
        case 'Body Not Recovered':
            result = 'No Body';
            break;
        default:
            result = 'Disposal Unknown';
            break;
    }
    return result;
}

const objToHTML = obj => {
    if (typeof obj === 'object' && obj !== null)
        // trap objects
        return Object.entries(obj).map(([key, value], index) => {
            if (!!obj.input) return objToHTML(obj.input); // skip graphql root
            return (
                <div className="prettyJSON-group" key={index}>
                    <div className="prettyJSON-label" onClick={e => e.target.classList.toggle('prettyJSON-open')}>
                        {`${Number(key) > -1 ? 'Item ' + (Number(key) + 1) : key}: `}
                        {Array.isArray(value) && value.length}
                    </div>
                    {objToHTML(value)}
                </div>
            );
        });
    if (true === obj) return <strong>true</strong>;
    if (false === obj) return <strong>false</strong>;
    if (null === obj || '' === obj) return <i>empty</i>;
    return <strong>{obj}</strong>;
};

export function prettyXML(val, hideLink = true, raw = false) {
    if (!val) return null;
    if (!!raw) return <pre>{val}</pre>;
    return <pre>{val}</pre>; //TODO something prettier?
}

export function prettyJSON(val, hideLink = true, raw = false) {
    if (!val) return null;
    if (!!raw) return <pre>{val}</pre>;
    try {
        const obj = JSON.parse(val);
        return <div className="prettyJSON">{objToHTML(obj)}</div>;
    } catch (err) {
        console.log('err', { err });
        return <pre style={{ whiteSpace: 'pre-wrap' }}>{val}</pre>;
    }
}

/**
 * grabs the url parameters and puts them into an object
 */
export function getUrlParams(location) {
    const searchKeys = location.search
        .replace('?', '')
        .split('&')
        .reduce((dic, kvpString) => {
            if (kvpString && kvpString.length > 0) {
                const kvp = kvpString.split('=');
                dic[kvp[0]] = kvp[1];
            }

            return dic;
        }, {});

    return searchKeys;
}

export const stringIsNullOrEmpty = str => {
    return str === null || str === undefined || str === '';
};

export const numberAsText = number => {
    const text = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
    return text.slice(number, 1 + number);
};

export const numberAsOrdinal = number => {
    const s = ['th', 'st', 'nd', 'rd'],
        v = number % 100;
    return number + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * capitalizes the first letter of the word
 */
export const capitalize = word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};
