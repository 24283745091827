import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { stringIsNullOrEmpty } from '../../util/strings';
import { getValidationDecorations } from '../../util/validation';
import FormHelperText from '@material-ui/core/FormHelperText';
import { onChangeWrapper } from '../../util/functions';

/**
 * Extension on the Checkbox from material ui adding:
 * - default theme/style
 * - wrapped with label
 * - optional wiring to provided form state
 */
class FormCheckbox extends Component {
    render() {
        const { label, classes, readOnly, InputProps, name, form, checked } = this.props;

        const decorations = getValidationDecorations(this.props, classes.root);

        if (readOnly || (InputProps && InputProps.readOnly)) {
            const value = form && name ? form.getField(name) : checked;
            return (
                <div className={classes.readOnlyBucket}>
                    <div className={cx(classes.readOnlyLabel, classes.label)}>{label}</div>
                    <div className={classes.readOnly}>{undefined === value ? '' : value ? 'Yes' : 'No'}</div>
                </div>
            );
        }

        return (
            <div className={cx(decorations.rootClass, 'no-wrap')}>
                {!stringIsNullOrEmpty(label) ? this.renderLabelWithCheckbox() : this.renderCheckbox()}
                {decorations.inError && (
                    <FormHelperText className={cx(classes.validationLabel, [decorations.validationLabel])}>
                        {decorations.errorMsg}
                    </FormHelperText>
                )}
            </div>
        );
    }

    renderLabelWithCheckbox() {
        const { classes, className, label } = this.props;
        return (
            <FormControlLabel
                className={cx(classes.labelRoot, className)}
                classes={{ label: classes.label }}
                control={this.renderCheckbox()}
                label={label}
            />
        );
    }

    renderCheckbox() {
        const {
            classes,
            className,
            id,
            name,
            form,
            checked,
            label,
            onChange,
            falseValue,
            InputProps,
            ...props
        } = this.props;
        return (
            <Checkbox
                id={id || (name ? `check-${name}` : undefined)}
                name={name}
                checked={(form ? form.getField(name) : checked) || false}
                onChange={e => onChangeWrapper(e, this, true)}
                color="primary"
                className={cx(classes.checkbox, className)}
                {...props}
            />
        );
    }
}

FormCheckbox.defaultProps = {
    falseValue: false
};

const styles = ({ palette, validationLabel }) => ({
    checkbox: {
        width: 36,
        height: 36,
        borderRadius: 5
    },
    labelRoot: {
        minWidth: 120,
        marginLeft: -8,
        marginRight: 8,
        fontSize: '0.875rem',
        '&:last-child': {
            fontSize: 'inherit' //allow you to change the size of font
        }
    },
    readOnlyLabel: {
        color: '#000',
        fontWeight: '500',
        fontSize: '0.95em',
        display: 'inline-block'
    },
    readOnly: {
        whiteSpace: 'pre-wrap',
        padding: '5px 5px 5px 0',
        fontSize: '0.95em',
        fontStyle: 'italic'
    },
    root: {
        display: 'inline-block',
        position: 'relative',
        marginBottom: 10,
        borderRadius: 3
    },
    validationErrorBorder: {
        border: `1px solid ${palette.validation.error} !important`,
        paddingLeft: 10,
        margin: -1,
        marginLeft: -11
    },
    validationErrorLabel: {
        color: `${palette.validation.error} !important`,
        border: `1px solid ${palette.validation.error} !important`
    },
    validationSuggestedBorder: {
        border: `1px solid ${palette.validation.suggested} !important`,
        paddingLeft: 10,
        margin: -1,
        marginLeft: -11
    },
    validationSuggestedLabel: {
        color: `${palette.validation.suggested} !important`,
        border: `1px solid ${palette.validation.suggested} !important`
    },
    validationOptionalBorder: {
        border: `1px solid ${palette.validation.optional} !important`,
        paddingLeft: 10,
        margin: -1,
        marginLeft: -11
    },
    validationOptionalLabel: {
        color: `${palette.validation.optional} !important`,
        border: `1px solid ${palette.validation.optional} !important`
    },
    validationLabel: { ...validationLabel, marginTop: 0 }
});

export default withStyles(styles)(FormCheckbox);
