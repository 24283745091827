import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Label from './Label';
import { onChangeWrapper } from '../../util/functions';
import { getValidationDecorations } from '../../util/validation';

/**
 * Combines the various material UI elements required to build a radio group.
 * - default theme/style
 * - wrapped with label
 * - optional wiring to provided form state
 */
class FormRadioGroup extends Component {
    render() {
        const {
            classes,
            className,
            name,
            row,
            form,
            value,
            options,
            label,
            onChange,
            onChanging,
            labelProps,
            groupProps,
            radioProps,
            labelField,
            valueField,
            disabled,
            ...other
        } = this.props;

        const id = `radio-${name}`;
        const { className: labelClassName, ...otherLabelProps } = labelProps;
        const { className: groupClassName, ...otherGroupProps } = groupProps;
        const currentValue = (value || (form && form.getField(name)) || '' || '') + '';
        const decorations = getValidationDecorations(this.props, cx(classes.group, classes.radioContainer));

        return (
            <FormControl className={cx(className, row && classes.row)} role="group" aria-labelledby={id} {...other}>
                {label && (
                    <Label
                        className={cx(classes.label, labelClassName)}
                        id={id}
                        text={label}
                        {...otherLabelProps}
                        disabled={disabled}
                    />
                )}
                <div className={decorations.rootClass}>
                    <RadioGroup
                        className={groupClassName}
                        aria-label={label}
                        name={name}
                        value={currentValue}
                        onChange={e => onChangeWrapper(e, this)}
                        row={row}
                        {...otherGroupProps}
                    >
                        {options.map((o, i) => (
                            <FormControlLabel
                                key={i}
                                value={valueField ? o[valueField] : o.value}
                                label={labelField ? o[labelField] : o.value}
                                disabled={disabled && currentValue !== o.value}
                                classes={{ label: classes.radioLabel }}
                                control={<Radio color="primary" {...radioProps} />}
                                {...o}
                            />
                        ))}

                        {decorations.inError && (
                            <FormHelperText className={cx(classes.validationLabel, [decorations.validationLabel])}>
                                {decorations.errorMsg}
                            </FormHelperText>
                        )}
                    </RadioGroup>
                </div>
            </FormControl>
        );
    }
}

FormRadioGroup.defaultProps = {
    row: true,
    fullWidth: true,
    labelProps: {},
    groupProps: {},
    radioProps: {}
};

const styles = ({ palette, breakpoints, validationLabel }) => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    label: {
        marginRight: 24
    },
    radioLabel: {
        fontSize: '0.875rem',
        marginLeft: -6,
        marginRight: 6
    },

    radioContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    validationErrorBorder: {
        margin: -1,
        marginLeft: -11,
        border: `1px solid ${palette.validation.error} !important`,
        paddingLeft: 10,
        borderRadius: 3
    },
    validationErrorLabel: {
        color: `${palette.validation.error} !important`,
        border: `1px solid ${palette.validation.error} !important`
    },
    validationSuggestedBorder: {
        margin: -1,
        marginLeft: -11,
        border: `1px solid ${palette.validation.suggested} !important`,
        paddingLeft: 10,
        borderRadius: 3
    },
    validationSuggestedLabel: {
        color: `${palette.validation.suggested} !important`,
        border: `1px solid ${palette.validation.suggested} !important`
    },
    validationOptionalBorder: {
        margin: -1,
        marginLeft: -11,
        border: `1px solid ${palette.validation.optional} !important`,
        paddingLeft: 10,
        borderRadius: 3
    },
    validationOptionalLabel: {
        color: `${palette.validation.optional} !important`,
        border: `1px solid ${palette.validation.optional} !important`
    },
    validationLabel: { ...validationLabel, marginTop: 0 }
});

export default withStyles(styles)(FormRadioGroup);
