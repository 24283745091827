import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Select from '../../../component/form/Select';
import RadioGroup from '../../../component/form/RadioGroup';
import TextField from '../../../component/form/TextField';
import PrimaryButton, { SaveButton } from '../../../component/form/PrimaryButton';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import ComboListAutoComplete from '../../../component/form/ComboListAutoComplete';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import {
    CHILD_AGE_OPTIONS,
    ChildFieldVerboseNames,
    createMarriageAddressComponents,
    GENDER_OPTIONS,
    LIFE_STATUS_OPTIONS,
    MARITAL_STATUS_OPTIONS,
    MarriageFieldVerboseNames,
    oppositeGender,
    PARENT_TYPE_OPTIONS
} from './FamilyConstants';
import { yearDelta } from '../../../util/date';
import moment from 'moment';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';
import { requirement, validationHelper } from '../../../util/validation';
import { isDefinedAndDifferent } from '../../../util/functions';
import { getClient } from '../../../apollo';
import gql from 'graphql-tag';
import { numberAsOrdinal } from '../../../util/strings';

class Family extends Component {
    state = {
        tempKids: []
    };

    restoreChild = gql`
        mutation restoreChild($input: CreateFuneralChildrenDetailInput!) {
            createFuneralChildrenDetail(input: $input) {
                ID
                LastEdited
                Created
                GivenName
                FamilyName
                FamilyNameAtBirth
                OtherGivenNames
                DateOfBirth
                Age
                AgeExtra
                Gender
                LifeStatus
            }
        }
    `;
    addMissingChild = input => {
        const { form } = this.props;
        input.FuneralID = form.getField('ID');
        getClient()
            .mutate({ mutation: this.restoreChild, variables: { input } })
            .then(({ data }) => {
                const children = form.getField('ChildrenDetails');
                const newChild = data.createFuneralChildrenDetail;
                delete newChild.__typename;
                children.push(newChild);
                form.setField({ ChildrenDetails: children }, false);
                this.setState({ tempKids: children });
            });
    };
    addChild = () => {
        const children = this.getChildren();

        children.push({
            ID: null,
            Age: '0',
            AgeExtra: 'Years',
            DateOfBirth: '2000-01-01',
            FamilyName: '', //removed surname by request
            GivenName: '',
            Gender: 'Unknown',
            LifeStatus: 'Alive',
            OtherGivenNames: ''
        });

        this.setChildren(children);
    };

    removeChild = i => {
        const children = this.getChildren();
        children.splice(i, 1);
        this.setChildren(children);
    };

    hasChildren = () => {
        return this.getChildren().length > 0;
    };

    onHasChildrenClicked = e => {
        const { form } = this.props;
        if (e.target.checked) {
            if (!this.hasChildren() && this.state.tempKids.length < 1) {
                this.addChild();
            } else {
                form.setField({ ChildrenDetails: this.state.tempKids });
            }
        } else if (!e.target.checked) {
            //            this.openAlertModal('Warning', 'This will remove children from the record. Are you sure?', () =>
            this.setState({ tempKids: form.getField('ChildrenDetails') });
            this.setChildren([]);
            //            );
        }
    };

    getChildren = () => {
        const { form } = this.props;
        if (form.getField('ChildrenDetails')) return form.getField('ChildrenDetails');

        return [];
    };

    setChildren = children => {
        const { form } = this.props;
        form.setField({ ChildrenDetails: children || [] });
    };

    getPreviousMarriages = () => {
        return this.getMarriages().filter((x, i) => i > 0);
    };

    getCurrentRelationship = () => {
        const marriages = this.getMarriages();
        const index = this.getCurrentRelationshipIndex();
        return index !== undefined ? marriages[index] : undefined;
    };

    getCurrentRelationshipIndex = () => {
        return 0;
    };

    getMarriages = () => {
        const { form } = this.props;
        if (form.getField('Marriages')) return form.getField('Marriages');

        return [];
    };

    setMarriages = marriages => {
        const { form } = this.props;
        form.setField({ Marriages: marriages || [] });
    };

    addMarriage = (marriageState = undefined) => {
        const { form } = this.props;
        const gender = form.getField('Gender');
        const marriages = this.getMarriages().map(a => ({ ...a }));

        marriages.push({
            Type: marriageState || 'Divorced',
            Gender: oppositeGender(gender),
            FirstName: '',
            MiddleName: '',
            Surname: '',
            AgeMarried: 0,
            Country: '',
            State: '',
            Suburb: ''
        });

        this.setMarriages(marriages);
    };

    removePreviousMarriage = i => {
        const marriages = this.getMarriages();
        marriages.splice(i, 1);
        this.setMarriages(marriages);
    };

    onHasPreviousMarriageClicked = e => {
        if (e.target.checked && !this.hasPreviousMarriage()) {
            this.addMarriage();
        } else if (!e.target.checked) {
            const index = this.getCurrentRelationshipIndex();
            const marriages = this.getMarriages();
            const newMarriages = index !== undefined ? [marriages[index]] : [];
            this.setMarriages(newMarriages);
        }
    };

    hasPreviousMarriage = () => {
        return this.getPreviousMarriages().length > 0;
    };

    onParentSurnameIsSameAsDeceasedClicked = e => {
        const { form } = this.props;
        const surname = e.target.checked ? form.getField('Surname') : null;

        form.setField({
            'Parent1.Surname': surname,
            'Parent2.Surname': surname
        });
    };

    onSpouseSurnameIsSameAsDeceasedClicked = e => {
        const { form } = this.props;
        const marriages = this.getMarriages();
        const index = this.getCurrentRelationshipIndex();
        marriages[index].CurrentSurname = e.target.checked ? form.getField('Surname') : null;
        this.setMarriages(marriages);
    };

    onCurrentMarriageStatusChanged = e => {
        if (this.getMarriages().length === 0) {
            this.addMarriage(e.target.value);
        }
    };

    showMarriageFields = () => {
        const currentRelationship = this.getCurrentRelationship();
        const lc = currentRelationship && currentRelationship.Type ? currentRelationship.Type.toLowerCase() : undefined;
        switch (lc) {
            case 'married':
            case 'defacto':
            case 'divorced':
            case 'widowed':
                return true;
            case 'never married':
            case 'unknown':
            case undefined:
                return false;
            default:
                throw new Error('unknown marital status ' + lc);
        }
    };

    spouseSurnameSameAsDeceased = () => {
        const { form } = this.props;
        const surname = (form.getField('Surname') || '').toLowerCase();
        const currentRelationship = this.getCurrentRelationship();
        const lc =
            currentRelationship && currentRelationship.CurrentSurname
                ? currentRelationship.CurrentSurname.toLowerCase()
                : undefined;
        return lc === surname;
    };

    parentsSurnameSameAsDeceased = () => {
        const { form } = this.props;
        const surname = (form.getField('Surname') || '').toLowerCase();
        const parent1Surname = (form.getField('Parent1.Surname') || '').toLowerCase();
        const parent2Surname = (form.getField('Parent2.Surname') || '').toLowerCase();
        return parent1Surname === surname && parent2Surname === surname;
    };

    dobIsUnknown = index => {
        const { form } = this.props;
        const childPrefix = `ChildrenDetails[${index}]`;
        return form.getField(`${childPrefix}.DateOfBirth`) === null;
    };

    onDobUnknownChanged = (e, childIndex) => {
        const children = this.getChildren();
        children[childIndex].DateOfBirth = e.target.checked ? null : '2000-01-01';
        this.setChildren(children);
    };

    onDobChanged = (e, childIndex) => {
        const children = this.getChildren();
        const child = children[childIndex];
        const { form } = this.props;
        const DateOfDeath1 = form.getField('DateOfDeath1');
        //const DateOfDeath2 = form.getField('DateOfDeath2');
        const DateOfDeathType = form.getField('DateOfDeathType');

        if (child.DateOfBirth && child.LifeStatus === 'Alive') {
            const dateOfBirth = new Date(e.target.value);
            let deathDate = new Date();
            switch (DateOfDeathType) {
                case 'Approximately':
                case 'On':
                case 'On or about':
                case 'On or After':
                case 'Between':
                    deathDate = new Date(DateOfDeath1);
                    break;
                case 'Unknown':
                default:
                    deathDate = new Date();
                    break;
            }
            child.Age = yearDelta(dateOfBirth, deathDate);
            if (child.Age > 1) {
                child.AgeExtra = 'Years';
                child.Age = String(child.Age);
            } else {
                const delta = ('' + moment(dateOfBirth).from(deathDate)).split(' ');

                child.Age = String(isNaN(delta[0]) ? 1 : delta[0]);
                child.AgeExtra = delta[1].replace(/^(\w)/, e => e.toUpperCase());

                if (['Years', 'Months', 'Weeks', 'Days', 'Hours'].indexOf(child.AgeExtra) === -1)
                    child.AgeExtra = child.AgeExtra + 's';
            }
        }
        this.setChildren(children);
    };

    onAgeChanged = (e, childIndex) => {
        const children = this.getChildren();
        const child = children[childIndex];

        if (child.LifeStatus === 'Alive') {
            const newAge = isNaN(e.target.value) ? child.Age : e.target.value;
            const newCode = !isNaN(e.target.value) ? child.AgeExtra : e.target.value;
            let diff = 0;
            let delta = moment(child.DateOfBirth);
            if (newCode === 'Years') {
                const newYear1 = moment(child.DateOfBirth).year(moment().year());
                const newYear2 = moment().year(moment().year());
                diff = newYear1 - newYear2 < 0 ? 0 : 1; // birthday hasn't happened this year?
            } else {
                delta = moment();
            }
            const newDOB = delta
                .year(moment().year())
                .subtract(Number(newAge) + diff, newCode)
                .format('YYYY-MM-DD');
            child.DateOfBirth = moment(newDOB).isValid() ? newDOB : null;
        }
        this.setChildren(children);
    };

    render() {
        const { form, locked } = this.props;
        const parentsSurnameSameAsDeceased = this.parentsSurnameSameAsDeceased();
        const currentRelationshipIndex = this.getCurrentRelationshipIndex();
        const currentChildCount = this.getChildren().length;
        let familyChildCount = null;
        const changes = form.getField('FamilyChangesFromBDM') || form.getField('FamilyChangesFromPrePlan') || {};
        if (changes.ChildrenDetails !== undefined && Array.isArray(changes.ChildrenDetails)) {
            familyChildCount = changes.ChildrenDetails.length;
        }
        return (
            <Fragment>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <RadioGroup
                            disabled={locked}
                            name={`Marriages[${currentRelationshipIndex}].Type`}
                            label="Current marital status"
                            options={MARITAL_STATUS_OPTIONS}
                            form={form}
                            value={form.getField(`Marriages[${currentRelationshipIndex}].Type`) || 'Unknown'}
                            onChange={this.onCurrentMarriageStatusChanged}
                        />
                    </Grid>

                    {this.showMarriageFields() && this.renderMarriageFields()}
                </Grid>

                <Grid bucket={true}>
                    <ValidationPlaceholder
                        validationResult={{
                            requirement: null === familyChildCount || familyChildCount === currentChildCount ? null : 0,
                            valid: true,
                            message: `Family entered: ${familyChildCount} children`
                        }}
                    >
                        <InlineHeader header="Children">
                            <Checkbox
                                disabled={locked}
                                label="The deceased had children"
                                name="HasChildren"
                                checked={this.hasChildren()}
                                onClick={this.onHasChildrenClicked}
                            />
                        </InlineHeader>
                    </ValidationPlaceholder>

                    {this.renderChildrenFields()}
                </Grid>

                <Grid bucket={true}>
                    <InlineHeader header="Parents">
                        <Checkbox
                            disabled={locked}
                            label="Surname same as deceased"
                            name="parentsSurnameSameAsDeceased"
                            checked={parentsSurnameSameAsDeceased}
                            onClick={this.onParentSurnameIsSameAsDeceasedClicked}
                        />
                    </InlineHeader>

                    {this.renderParent('Parent1')}
                    {this.renderParent('Parent2')}
                </Grid>
            </Fragment>
        );
    }

    renderMarriageFields() {
        const { locked } = this.props;
        const marriages = this.getMarriages();
        const spouseSurnameSameAsDeceased = this.spouseSurnameSameAsDeceased();
        const hasPreviousMarriage = this.hasPreviousMarriage();
        const currentRelationshipIndex = this.getCurrentRelationshipIndex();

        return (
            <Fragment>
                <InlineHeader header="Spouse details">
                    <Checkbox
                        disabled={locked}
                        label="Current surname same as deceased"
                        name="spouseSurnameSameAsDeceased"
                        checked={spouseSurnameSameAsDeceased}
                        onClick={this.onSpouseSurnameIsSameAsDeceasedClicked}
                    />
                </InlineHeader>

                {this.renderMarriageDetails(currentRelationshipIndex, `Marriages[${currentRelationshipIndex}].`, null)}

                <InlineHeader header="Previous marriages">
                    <Checkbox
                        disabled={locked}
                        label="The deceased had previous marriages"
                        name="hasPreviousMarriage"
                        onClick={this.onHasPreviousMarriageClicked}
                        checked={hasPreviousMarriage}
                    />
                </InlineHeader>
                {hasPreviousMarriage ? (
                    <Fragment>
                        {marriages.map((obj, i) =>
                            i !== currentRelationshipIndex
                                ? this.renderMarriageDetails(i, `Marriages[${i}].`, () =>
                                      this.removePreviousMarriage(i)
                                  )
                                : undefined
                        )}
                    </Fragment>
                ) : (
                    <Fragment />
                )}
                {hasPreviousMarriage && (
                    <InlineField>
                        <PrimaryButton disabled={locked} onClick={() => this.addMarriage()}>
                            + Add Previous Marriage
                        </PrimaryButton>
                    </InlineField>
                )}
            </Fragment>
        );
    }

    renderMarriageDetails(index, propertyBase, removeMarriage) {
        const { form, classes, locked } = this.props;
        const marriageAddressComponents = createMarriageAddressComponents(index);
        const spouseSurnameSameAsDeceased = this.spouseSurnameSameAsDeceased();
        const maritalStatusOptions = MARITAL_STATUS_OPTIONS.filter(
            e => ['Defacto', 'Never married'].indexOf(e.value) < 0
        ).map(e => {
            const obj = { ...e };
            if (obj.value === 'Married') obj.label = 'Marriage not ended';
            return obj;
        });
        const changes = form.getField('FamilyChangesFromBDM') || form.getField('FamilyChangesFromPrePlan') || {};
        const validationResult = validationHelper.ok();
        if (!!changes && !!changes.Marriages && Array.isArray(changes.Marriages)) {
            const marriageID = form.getField(`${propertyBase}ID`);
            const thatMarriage = changes.Marriages.filter(value => value.ID === marriageID);
            if (thatMarriage.length === 0) {
                validationResult.requirement = requirement.optional;
                validationResult.message = 'This marriage was not included by the family';
            } else {
                const marriageChanges = [];
                MarriageFieldVerboseNames.forEach(element => {
                    const original = thatMarriage[0][element.Field];
                    const change = form.getField(`${propertyBase}${element.Field}`);
                    if (isDefinedAndDifferent(original, change)) {
                        marriageChanges.push(`${element.Name} = ${original || '(empty)'}`);
                    }
                });
                if (marriageChanges.length > 0) {
                    validationResult.requirement = requirement.optional;
                    validationResult.message = 'Family entered: ' + marriageChanges.join('; ');
                }
            }
        }

        return (
            <ValidationPlaceholder key={index} validationResult={validationResult}>
                <InlineField className={classes.listItem}>
                    <Grid container spacing={24}>
                        {!!removeMarriage && (
                            <Grid item xs>
                                <Select
                                    disabled={locked}
                                    label="Reason ended"
                                    name={`${propertyBase}Type`}
                                    form={form}
                                    allowNone={false}
                                    options={maritalStatusOptions}
                                    className={classes.minWidth}
                                />
                            </Grid>
                        )}
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="First name"
                                name={`${propertyBase}FirstName`}
                                form={form}
                                className={classes.minWidth}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Middle name(s)"
                                name={`${propertyBase}MiddleName`}
                                form={form}
                                className={classes.minWidth}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Surname before this marriage"
                                name={`${propertyBase}Surname`}
                                form={form}
                                className={classes.minWidth}
                            />
                        </Grid>
                        {!removeMarriage && !spouseSurnameSameAsDeceased && (
                            <Grid item xs>
                                <TextField
                                    disabled={locked}
                                    titleCase
                                    label="Current Surname"
                                    name={`${propertyBase}CurrentSurname`}
                                    form={form}
                                    className={classes.minWidth}
                                />
                            </Grid>
                        )}
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                label="Gender"
                                name={`${propertyBase}Gender`}
                                form={form}
                                options={GENDER_OPTIONS}
                                className={classes.minWidth}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                type="number"
                                inputProps={{ min: '0' }}
                                label="At Age"
                                name={`${propertyBase}AgeMarried`}
                                form={form}
                            />
                        </Grid>
                        <Grid item xs>
                            <AddressAutocomplete
                                disabled={locked}
                                onlySuburbs={true}
                                id={`${propertyBase}`}
                                label="Place of Marriage"
                                placeholder="Search for an address"
                                componentFields={marriageAddressComponents}
                                form={form}
                                allowCustomAddress={true}
                            />
                        </Grid>
                        {!!removeMarriage && !locked && (
                            <Grid item className={classes.alignWithLabel}>
                                <Grid item className={classes.alignChildrenRight}>
                                    <IconButton title={'Delete'} onClick={removeMarriage}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </InlineField>
            </ValidationPlaceholder>
        );
    }

    renderChildrenFields() {
        const { locked, form } = this.props;
        const children = this.getChildren();
        const changes = form.getField('FamilyChangesFromBDM') || form.getField('FamilyChangesFromPrePlan') || {};
        let missingKids = [];
        if (changes.ChildrenDetails !== undefined && Array.isArray(changes.ChildrenDetails)) {
            const familyIDs = changes.ChildrenDetails.map(obj => obj.ID);
            const currentIDs = children.map(obj => obj.ID);
            missingKids = familyIDs.filter(id => !currentIDs.includes(id));
        }
        return (
            <Fragment>
                {children.map((c, i) => this.renderChild(i))}
                <InlineField>
                    {!locked &&
                        missingKids.map((id, idx) => (
                            <SaveButton
                                key={idx}
                                onClick={() => this.addMissingChild(changes.ChildrenDetails.find(obj => obj.ID === id))}
                            >
                                + Add {missingKids.length > 1 ? numberAsOrdinal(idx + 1) : ''} missing child
                            </SaveButton>
                        ))}
                    {!locked && this.hasChildren() && (
                        <PrimaryButton disabled={locked} onClick={this.addChild}>
                            + Add child
                        </PrimaryButton>
                    )}
                </InlineField>
            </Fragment>
        );
    }

    renderChild(index) {
        const { form, classes, locked } = this.props;

        const childPrefix = `ChildrenDetails[${index}]`;
        var dobUnknown = this.dobIsUnknown(index);
        var ded = form.getField(`${childPrefix}.LifeStatus`) !== 'Alive';
        var stillborn = form.getField(`${childPrefix}.LifeStatus`) === 'Stillborn';
        const changes = form.getField('FamilyChangesFromBDM') || form.getField('FamilyChangesFromPrePlan') || {};
        const validationResult = validationHelper.ok();
        if (changes.ChildrenDetails !== undefined && Array.isArray(changes.ChildrenDetails)) {
            const childID = form.getField(`${childPrefix}.ID`);
            const thatChild = changes.ChildrenDetails.filter(value => value.ID === childID);
            if (thatChild.length === 0) {
                validationResult.requirement = requirement.optional;
                validationResult.message = 'This child was not included by the family';
            } else {
                const childChanges = [];
                ChildFieldVerboseNames.forEach(element => {
                    const original = thatChild[0][element.Field];
                    const change = form.getField(`${childPrefix}.${element.Field}`);
                    if (original !== change && (!!original || !!change)) {
                        childChanges.push(`${element.Name} = ${original || '(empty)'}`);
                    }
                });
                if (childChanges.length > 0) {
                    validationResult.requirement = requirement.optional;
                    validationResult.message = 'Family entered: ' + childChanges.join('; ');
                }
            }
        }
        const disabledAge = !dobUnknown && !ded;

        return (
            <ValidationPlaceholder key={index} validationResult={validationResult}>
                <InlineField className={classes.listItem}>
                    <Grid container spacing={24}>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="First name"
                                name={`${childPrefix}.GivenName`}
                                form={form}
                                className={classes.minWidth}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Middle name(s)"
                                name={`${childPrefix}.OtherGivenNames`}
                                form={form}
                                className={classes.minWidth}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Surname"
                                name={`${childPrefix}.FamilyName`}
                                form={form}
                                className={classes.minWidth}
                            />
                        </Grid>
                        {1 === 2 && (
                            <Grid item xs>
                                <TextField
                                    disabled={locked}
                                    titleCase
                                    label="Surname at birth"
                                    name={`${childPrefix}.FamilyNameAtBirth`}
                                    form={form}
                                    className={classes.minWidth}
                                />
                            </Grid>
                        )}
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                allowNone={false}
                                name={`${childPrefix}.Gender`}
                                form={form}
                                options={GENDER_OPTIONS}
                                label={'Gender'}
                                className={classes.minWidth}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                allowNone={false}
                                name={`${childPrefix}.LifeStatus`}
                                form={form}
                                options={LIFE_STATUS_OPTIONS}
                                label={'Life status'}
                                className={classes.minWidth}
                            />
                        </Grid>

                        {!dobUnknown && (
                            <Grid item xs>
                                <TextField
                                    disabled={locked}
                                    label="Date of birth"
                                    name={`${childPrefix}.DateOfBirth`}
                                    type="date"
                                    notFuture
                                    form={form}
                                    onChange={e => this.onDobChanged(e, index)}
                                    className={classes.minWidth}
                                />
                            </Grid>
                        )}

                        <Grid item xs className={classes.alignWithFloatingLabel}>
                            <Checkbox
                                disabled={locked}
                                label="Date&nbsp;of&nbsp;Birth unknown"
                                name="dobUnknown"
                                onChange={e => this.onDobUnknownChanged(e, index)}
                                checked={dobUnknown}
                            />
                        </Grid>

                        {!stillborn && (
                            <Grid item xs>
                                <InlineField>
                                    <TextField
                                        disabled={locked || disabledAge}
                                        className={classes.kidAge}
                                        label="Was Aged"
                                        name={`${childPrefix}.Age`}
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e => this.onAgeChanged(e, index)}
                                    />
                                    <Select
                                        disabled={locked || disabledAge}
                                        className={classes.kidAgeType}
                                        label={''}
                                        form={form}
                                        options={CHILD_AGE_OPTIONS}
                                        name={`${childPrefix}.AgeExtra`}
                                        onChange={e => this.onAgeChanged(e, index)}
                                    />
                                </InlineField>
                            </Grid>
                        )}
                        {!locked && (
                            <Grid item className={classes.alignWithLabel}>
                                <Grid item className={classes.alignChildrenRight}>
                                    <IconButton title={'Delete'} onClick={() => this.removeChild(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </InlineField>
            </ValidationPlaceholder>
        );
    }

    renderParent(property) {
        const { form, classes, locked } = this.props;

        return (
            <InlineField key={property} className={classes.listItem}>
                <Grid container spacing={24}>
                    <Grid item xs>
                        <Select
                            disabled={locked}
                            label={'Parent Type'}
                            allowNone={false}
                            className={classes.minWidth}
                            name={`${property}.Type`}
                            form={form}
                            options={PARENT_TYPE_OPTIONS}
                        />
                    </Grid>
                    {'Parent' === form.getField(`${property}.Type`) && (
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                label={'Gender'}
                                allowNone={false}
                                className={classes.minWidth}
                                name={`${property}.Gender`}
                                form={form}
                                options={GENDER_OPTIONS}
                            />
                        </Grid>
                    )}
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="First name"
                            name={`${property}.FirstName`}
                            form={form}
                            className={classes.minWidth}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="Middle name(s)"
                            name={`${property}.MiddleName`}
                            form={form}
                            className={classes.minWidth}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="Surname at birth"
                            name={`${property}.SurnameAtBirth`}
                            form={form}
                            className={classes.minWidth}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="Surname"
                            name={`${property}.Surname`}
                            form={form}
                            className={classes.minWidth}
                        />
                    </Grid>
                    <Grid item xs>
                        <ComboListAutoComplete
                            disabled={locked}
                            label="Occupation"
                            placeholder="Search..."
                            name={`${property}.Occupation`}
                            form={form}
                            className={classes.minWidth}
                            category={'OCCUPATION'}
                        />
                    </Grid>
                </Grid>
            </InlineField>
        );
    }
}

const styles = ({ spacing }) => ({
    noColumn: { margin: `-${spacing.unit * 3}px ${spacing.unit}px ${spacing.unit * 3}px ${spacing.unit}px` },
    alignWithFloatingLabel: {
        marginTop: 16,
        minWidth: 140
    },
    minWidth: {
        minWidth: 140
    },
    alignWithLabel: {
        marginTop: 10,
        minWidth: 140
    },
    padButton: {
        margin: '15px 0'
    },
    kidAge: {
        width: 94,
        flexShrink: 0,
        marginRight: -14,
        '& > div': {
            minWidth: 'unset'
        }
    },
    kidAgeType: {
        maxWidth: '10rem',
        marginTop: 16
    },
    alignChildrenRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    listItem: {
        width: '100%',
        padding: '0 6px 0 !important',
        margin: '6px 6px 0',
        borderRadius: 5,
        '& > div ': {
            marginBottom: '16px!important',
            marginTop: 0
        },
        '& > div > div': {
            paddingBottom: '0!important'
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.035)'
        }
    }
});

export default withStyles(styles)(Family);
