import gql from 'graphql-tag';
import EventConflictPartial from './EventConflictPartial';
import { BookingsFragmentInclude } from './Bookings';

export default gql`

    fragment Disposal on Funeral {
        Disposal {
            ID
            CrematedOrBuried
            Date
            Time
            Duration
            Private
            NoAttendance
            CemeterySection
            SameDayCremation
            InsertionDate
            Confirmed
            Location {
                ID
                Name
            }
            DisposalBookingItems {
                ID
                Title
                Quantity
                UnitPrice
                Total
                Comment
                Returns
                Product {
                    ID
                    InternalItemID
                    Title
                    ShortDescription
                    GST
                    Parent {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                    ProductCategories {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                }
                Variation {
                    ID
                    InternalItemID
                    ShortDescription
                    GST
                }
            }
            StaffAllocations {
                ID
                Title
                Allocation
                Start
                End
                Type
                Location
                Comments
                Recurring
                Member {
                    ID
                    FirstName
                    Surname
                    Email
                }
            }
            Bookings {
                ${BookingsFragmentInclude}
            }
            EventConflicts {
                ${EventConflictPartial}
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementDisposal on Prearrangement {
        ID
        Disposal {
            ID
            CrematedOrBuried
            Date
            #            Time
            Private
            NoAttendance
            CemeterySection
            Location {
                ID
                Name
            }
            DisposalBookingItems {
                ID
                Quantity
                UnitPrice
                Total
                Comment
                Returns
                Product {
                    ID
                    Parent {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                    ProductCategories {
                        ... on ProductCategory {
                            ID
                            URLSegment
                        }
                        ... on CoreProductCategory {
                            ID
                            URLSegment
                        }
                    }
                }
                Variation {
                    ID
                }
            }
        }
    }
`;
