import Details from './Details';
import DetailsFragment from './DetailsFragment';
import { flattenConnection, flattenStaffAllocations } from '../../../util/functions';
import {
    cancelAllBookings,
    isRelatedObjectDefined,
    prepareBookingsForSave,
    prepareDisposalForSave,
    prepareServiceForSave,
    prepareViewingsForSave,
    sanitizeObjectWithFK
} from '../../../util/bookable';
import { flattenBookingItems } from '../../../util/products';
import { joinValidationResults, messages, validationHelper } from '../../../util/validation';
import { joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import { isRelatedObjectUndefined } from '../../../util/graphql';
import { isNullOrUndefined } from '../../../util/objects';
import moment from 'moment';

export default {
    label: 'Funeral Details',
    component: Details,
    fragment: DetailsFragment,
    onLoad: data => {
        flattenConnection(data, 'Cars');
        flattenConnection(data, 'Clergy');
        flattenConnection(data, 'GraveLedger');
        flattenConnection(data, 'CoffinBearings');
        flattenConnection(data, 'LimoBookings');
        flattenConnection(data, 'PlaceOfViewingItems');
        flattenConnection(data, 'ValuableItems');

        if (data.Disposal) {
            flattenBookingItems(data.Disposal, 'DisposalBookingItems');
            data.Disposal.DisposalBookingItems = (data.Disposal.DisposalBookingItems || []).filter(x =>
                isRelatedObjectDefined(x)
            );
            flattenStaffAllocations(data.Disposal);
        }
        if (data.PlaceOfService) {
            flattenStaffAllocations(data.PlaceOfService);
        }
        if (data.PlaceOfViewingItems) {
            for (let x = 0; x < data.PlaceOfViewingItems.length; x++)
                flattenStaffAllocations(data.PlaceOfViewingItems[x]);
        }

        if (data.Clergy && data.Clergy.filter(e => !e.Cancelled).length > 0) data.ClergyRequired = true;

        if (data.ReflectionRoomRequired && data.ReflectionRoomRequired !== 'Required' && !data.ReflectionRoom) {
            data.ReflectionRoom = { ID: 0 };
        }
    },
    formatSaveData: (saveData, state, original) => {
        if (saveData.AshesDetail) saveData.AshesDetail = sanitizeObjectWithFK(saveData.AshesDetail, 'Crematorium');

        if (saveData.WebcastRequired === null) {
            saveData.WebcastComment = null;
            saveData.WebcastConfirmed = false;
            saveData.WebcastEmailedLinkToFamily = false;
        }

        // Clear the bookable items when item set to not required
        if (state.GraveLedgerRequired === false && state.GraveLedger && state.GraveLedger.length)
            saveData.GraveLedger = cancelAllBookings(state.GraveLedger);
        if (state.LimoRequired === false && state.LimoBookings && state.LimoBookings.length)
            saveData.LimoBookings = cancelAllBookings(state.LimoBookings);
        if (state.ClergyRequired === false && state.Clergy && state.Clergy.length)
            saveData.Clergy = cancelAllBookings(state.Clergy);

        const items = ['Clergy', 'GraveLedger', 'LimoBookings'];
        items.forEach(item => {
            if (saveData[item]) {
                saveData[item] = prepareBookingsForSave(saveData[item]);
            }
        });

        if (saveData.CoffinBearings) {
            saveData.CoffinBearings = [];
            for (let x = 0; x < state.CoffinBearings.length; x++) {
                saveData.CoffinBearings[x] = { ...state.CoffinBearings[x] };
                if (saveData.CoffinBearings[x].Address instanceof Object) {
                    const location = joinDefined(
                        [saveData.CoffinBearings[x].Address.Suburb, saveData.CoffinBearings[x].Address.State],
                        ' '
                    );
                    saveData.CoffinBearings[x].Address = `${saveData.CoffinBearings[x].Address.Name} (${location})`;
                }
                if (saveData.CoffinBearings[x].NumberOfFamilyRequired) {
                    saveData.CoffinBearings[x].NumberOfFamilyRequired = parseInt(
                        saveData.CoffinBearings[x].NumberOfFamilyRequired
                    );
                }
                if (saveData.CoffinBearings[x].NumberOfStaffRequired) {
                    saveData.CoffinBearings[x].NumberOfStaffRequired = parseInt(
                        saveData.CoffinBearings[x].NumberOfStaffRequired
                    );
                }
            }
        }

        if (saveData.Disposal) {
            prepareDisposalForSave(saveData, state, original);
        }

        if (saveData.PlaceOfService) {
            prepareServiceForSave(saveData, state, original);
        }

        if (saveData.PlaceOfViewingItems) {
            if (!state.PlaceOfViewingRequired) {
                saveData.PlaceOfViewingItems = [];
            } else {
                prepareViewingsForSave(saveData, state, original);
            }
        }

        //Valuables Data is saved in another form
        if (!isNullOrUndefined(saveData.ValuableItems)) {
            delete saveData.ValuableItems;
        }

        if (state.ReflectionRoomRequired !== 'Required' && state.ReflectionRoom) {
            delete saveData.ReflectionRoom;
            delete state.ReflectionRoom;
        }
    },

    validation: {
        required: [],
        suggested: [],
        onValidate: {
            WebcastComment: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('WebcastRequired')) return validationHelper.ok();

                return isNullOrUndefined(persistedValue) ? validationHelper.suggested() : validationHelper.ok();
            },

            'PlaceOfService.Location': (newValue, persistedValue, hasValue, getField) => {
                if (getField('PlaceOfService.Type') === 'No Service No Attendance') return validationHelper.ok();
                if (getField('PlaceOfService.Type') === 'Graveside') return validationHelper.ok();
                return !isRelatedObjectUndefined(persistedValue) || !!newValue
                    ? validationHelper.ok()
                    : validationHelper.suggested();
            },

            'PlaceOfService.Date': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('PlaceOfService.Type') === 'No Service No Attendance' ||
                    getField('PlaceOfService.Type') === 'Graveside' ||
                    isRelatedObjectUndefined(getField('PlaceOfService.Location'))
                )
                    return validationHelper.ok();

                return stringIsNullOrEmpty(persistedValue) ? validationHelper.suggested() : validationHelper.ok();
            },

            'PlaceOfService.Time': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('PlaceOfService.Type') === 'No Service No Attendance' ||
                    getField('PlaceOfService.Type') === 'Graveside' ||
                    isRelatedObjectUndefined(getField('PlaceOfService.Location'))
                )
                    return validationHelper.ok();

                return stringIsNullOrEmpty(persistedValue) ? validationHelper.suggested() : validationHelper.ok();
            },

            'PlaceOfService.Duration': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('PlaceOfService.Type') === 'No Service No Attendance' ||
                    isRelatedObjectUndefined(getField('PlaceOfService.Location'))
                )
                    return validationHelper.ok();

                return isNullOrUndefined(persistedValue) ? validationHelper.required() : validationHelper.ok();
            },

            'PlaceOfService.Type': (newValue, persistedValue, hasValue, getField) => {
                return isNullOrUndefined(persistedValue) ? validationHelper.required() : validationHelper.ok();
            },

            PlaceOfViewingItems: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('PlaceOfViewingRequired')) return validationHelper.ok();

                if ((persistedValue || []).length === 0) {
                    return validationHelper.required(messages.atLeastOne('Place Of Viewing'), [
                        `PlaceOfViewingRequired`
                    ]);
                }

                const validationResults = [];
                (persistedValue || []).forEach((placeOfViewing, index) => {
                    const invalidFields = [];

                    if (isRelatedObjectUndefined(placeOfViewing.Location)) {
                        invalidFields.push(`PlaceOfViewingItems[${index}].Location`);
                    }

                    if (stringIsNullOrEmpty(placeOfViewing.Date)) {
                        invalidFields.push(`PlaceOfViewingItems[${index}].Date`);
                    }

                    if (stringIsNullOrEmpty(placeOfViewing.Time)) {
                        invalidFields.push(`PlaceOfViewingItems[${index}].Time`);
                    }

                    if (stringIsNullOrEmpty(placeOfViewing.Duration)) {
                        invalidFields.push(`PlaceOfViewingItems[${index}].Duration`);
                    }

                    if (stringIsNullOrEmpty(placeOfViewing.Type)) {
                        invalidFields.push(`PlaceOfViewingItems[${index}].Type`);
                    }

                    if (invalidFields.length > 0)
                        validationResults.push(validationHelper.required(messages.required, invalidFields));
                });

                return joinValidationResults(validationResults);
            },

            'Disposal.Location': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('Disposal.NoAttendance') === true ||
                    getField('Disposal.CrematedOrBuried') === 'Body Not Recovered'
                )
                    return validationHelper.ok();
                return isRelatedObjectUndefined(persistedValue) ? validationHelper.suggested() : validationHelper.ok();
            },

            'Disposal.Date': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('Disposal.NoAttendance') === true ||
                    getField('Disposal.CrematedOrBuried') === 'Body Not Recovered' ||
                    isRelatedObjectUndefined(getField('Disposal.Location'))
                )
                    return validationHelper.ok();
                return stringIsNullOrEmpty(persistedValue) ? validationHelper.required() : validationHelper.ok();
            },

            'Disposal.Time': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('Disposal.NoAttendance') === true ||
                    getField('Disposal.CrematedOrBuried') === 'Body Not Recovered' ||
                    isRelatedObjectUndefined(getField('Disposal.Location'))
                )
                    return validationHelper.ok();
                if (stringIsNullOrEmpty(persistedValue)) {
                    if (getField('Disposal.CrematedOrBuried') === 'Repatriated') return validationHelper.suggested();
                    return validationHelper.required();
                }
                return validationHelper.ok();
            },

            'Disposal.InsertionDate': (newValue, persistedValue, hasValue, getField) => {
                const notRequired = 'Cremated' !== getField('Disposal.CrematedOrBuried');
                if (notRequired) return validationHelper.ok();
                const deliveredDate = getField('Disposal.Date');
                const insertionDate = getField('Disposal.InsertionDate');
                const exportDate = getField('ExportDate');
                if (!exportDate && !!deliveredDate && moment().isAfter(deliveredDate)) {
                    return !!insertionDate
                        ? validationHelper.ok()
                        : validationHelper.required('This field is required for death registration');
                }
                return validationHelper.ok();
            },

            'FuneralDressing.Status': (newValue, persistedValue, hasValue, getField) => {
                return stringIsNullOrEmpty(persistedValue) && !getField('FuneralDressing.Shroud')
                    ? validationHelper.suggested()
                    : validationHelper.ok();
            },

            'AshesDetail.Crematorium': (newValue, persistedValue, hasValue, getField) => {
                if (!getField('AshesDetail.FamilyHasReservation') || !isRelatedObjectUndefined(persistedValue))
                    return validationHelper.ok();

                return validationHelper.required();
            },

            'Grave.GroundDescription': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Disposal.CrematedOrBuried') !== 'Buried' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Grave.DepthOption': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Disposal.CrematedOrBuried') !== 'Buried' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Grave.PortionGardenName': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Disposal.CrematedOrBuried') !== 'Buried' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Grave.BeamRowLot': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Disposal.CrematedOrBuried') !== 'Buried' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Grave.SideSection': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Disposal.CrematedOrBuried') !== 'Buried' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Grave.Number': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Disposal.CrematedOrBuried') !== 'Buried' || !stringIsNullOrEmpty(persistedValue))
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            HearseToPassHouseDate: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('HearseToPassHouse')) return validationHelper.ok();

                return validateHearseField('HearseToPassHouseDate', 'HearseToPassHouseTime', persistedValue, getField);
            },
            HearseToPassHouseTime: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('HearseToPassHouse')) return validationHelper.ok();

                return validateHearseField('HearseToPassHouseTime', 'HearseToPassHouseDate', persistedValue, getField);
            },

            HearseToPassHouseAfterServiceDate: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('HearseToPassHouseAfterService')) return validationHelper.ok();

                return validateHearseField(
                    'HearseToPassHouseAfterServiceDate',
                    'HearseToPassHouseAfterServiceTime',
                    persistedValue,
                    getField
                );
            },
            HearseToPassHouseAfterServiceTime: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('HearseToPassHouseAfterService')) return validationHelper.ok();

                return validateHearseField(
                    'HearseToPassHouseAfterServiceTime',
                    'HearseToPassHouseAfterServiceDate',
                    persistedValue,
                    getField
                );
            },

            HearseDriveAwayDate: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('HearseDriveAway')) return validationHelper.ok();

                return validateHearseField('HearseDriveAwayDate', 'HearseDriveAwayTime', persistedValue, getField);
            },
            HearseDriveAwayTime: (newValue, persistedValue, hasValue, getField) => {
                if (!getField('HearseDriveAway')) return validationHelper.ok();

                return validateHearseField('HearseDriveAwayTime', 'HearseDriveAwayDate', persistedValue, getField);
            }
        }
    }
};

const hearseFields = [
    'HearseToPassHouseDate',
    'HearseToPassHouseTime',

    'HearseToPassHouseAfterServiceDate',
    'HearseToPassHouseAfterServiceTime',

    'HearseDriveAwayDate',
    'HearseDriveAwayTime'
];

//tricky validation for hearse fields
const validateHearseField = (fieldName, coField, persistedValue, getField) => {
    //if hearse isnt required... dont worry
    const fieldHasValue = !stringIsNullOrEmpty(persistedValue);
    if (!getField('HearseRequired') || fieldHasValue) {
        return validationHelper.ok();
    }

    //if my coField has a value, i need a value
    const coFieldValue = getField(coField);
    if (!stringIsNullOrEmpty(coFieldValue)) {
        return validationHelper.required();
    }

    //if me or my coField dont have values, one of these fields need to havea value
    let otherFieldHasValue = false;
    for (let x = 0; x < hearseFields.length && !otherFieldHasValue; x++) {
        if (hearseFields[x] === fieldName || hearseFields[x] === coField) continue;

        const otherFieldValue = getField(hearseFields[x]);
        otherFieldHasValue = !stringIsNullOrEmpty(otherFieldValue);
    }

    return otherFieldHasValue ? validationHelper.ok() : validationHelper.required();
};
