import Family from './Family';
import FamilyFragment from './FamilyFragment';
import { flattenConnection, isDefinedAndDifferent } from '../../../util/functions';
import { isNullOrUndefined } from '../../../util/objects';
import { stringIsNullOrEmpty } from '../../../util/strings';
import { joinValidationResults, messages, requirement, validationHelper } from '../../../util/validation';
import { ChildFieldVerboseNames, MarriageFieldVerboseNames } from './FamilyConstants';

export default {
    label: 'Family',
    component: Family,
    fragment: FamilyFragment,
    onLoad: data => {
        flattenConnection(data, 'ChildrenDetails');
        flattenConnection(data, 'Marriages');
        if (!!data.FamilyChangesFromBDM) {
            data.FamilyChangesFromBDM = JSON.parse(data.FamilyChangesFromBDM || '{}');
        }
        if (!!data.FamilyChangesFromPrePlan) {
            data.FamilyChangesFromPrePlan = JSON.parse(data.FamilyChangesFromPrePlan || '{}');
        }
    },

    formatSaveData: (saveData, state) => {
        if (!!saveData.Marriages && saveData.Marriages.length > 0) {
            state.Marriages.forEach((marriage, index) => {
                if (index === 0) {
                    if (marriage.Type === 'Unknown') {
                        saveData.Marriages = [];
                    } else if (marriage.Type === 'Never married') {
                        saveData.Marriages = [{ Type: marriage.Type }];
                    }
                }
            });
        }
        if (!!saveData.Marriages && saveData.Marriages.length > 0) {
            saveData.Marriages.forEach(marriage => {
                if (isNaN(marriage.AgeMarried) || 0.1 > Number(marriage.AgeMarried)) marriage.AgeMarried = null;
                else marriage.AgeMarried = Number(marriage.AgeMarried);
            });
        }
        if (!!saveData.ChildrenDetails && saveData.ChildrenDetails.length > 0) {
            saveData.ChildrenDetails.forEach(child => {
                if (isNaN(child.Age)) child.Age = null;
                else child.Age = '' + child.Age;
            });
        }
        delete saveData.FamilyChangesFromBDM;
        delete saveData.FamilyChangesFromPrePlan;
    },

    validation: {
        onValidate: {
            'Marriages[0].FirstName': (newValue, persistedValue, hasValue, getField) => {
                const marriage = getField('Marriages')[0] || {};
                if (marriage.Type === 'Married') {
                    if (stringIsNullOrEmpty(marriage.FirstName)) {
                        return validationHelper.required();
                    }
                }
                return validationHelper.ok();
            },
            'Marriages[0].AgeMarried': (newValue, persistedValue, hasValue, getField) => {
                const marriage = getField('Marriages')[0] || {};
                if (marriage.Type === 'Married') {
                    if (!Number(marriage.AgeMarried)) {
                        return validationHelper.required();
                    }
                }
                return validationHelper.ok();
            },
            'Marriages[0].Suburb': (newValue, persistedValue, hasValue, getField) => {
                const marriage = getField('Marriages')[0] || {};
                if (marriage.Type === 'Married') {
                    if (stringIsNullOrEmpty(marriage.Suburb)) {
                        return validationHelper.required();
                    }
                }
                return validationHelper.ok();
            },
            // can't validate a list, so this just increments the purple badge by one
            Marriages: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || getField('FamilyChangesFromPrePlan') || {};
                const validationResults = [];
                if (!!changes && !!changes.Marriages && Array.isArray(changes.Marriages)) {
                    (persistedValue || []).forEach((marriage, index) => {
                        const validationResult = validationHelper.ok();
                        const propertyBase = `Marriages[${index}].`;
                        const marriageID = getField(`${propertyBase}ID`);
                        const thatMarriage = changes.Marriages.filter(value => value.ID === marriageID);
                        if (thatMarriage.length === 0) {
                            validationResult.requirement = requirement.optional;
                            validationResult.message = '';
                        } else {
                            const familyChanges = [];
                            MarriageFieldVerboseNames.forEach(element => {
                                const original = thatMarriage[0][element.Field];
                                const change = getField(`${propertyBase}${element.Field}`);
                                if (isDefinedAndDifferent(original, change)) {
                                    familyChanges.push(1);
                                }
                            });
                            if (familyChanges.length > 0) {
                                validationResult.requirement = requirement.optional;
                                validationResult.message = '';
                            }
                        }
                        validationResults.push(validationResult);
                    });
                }
                return joinValidationResults(validationResults);
            },
            // again, validate items but can only increment purple badge
            ChildrenDetails: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || getField('FamilyChangesFromPrePlan') || {};
                const validationResults = [];
                (persistedValue || []).forEach((child, index) => {
                    const invalidFields = [];

                    if (stringIsNullOrEmpty(child.GivenName)) {
                        invalidFields.push(`ChildrenDetails[${index}].GivenName`);
                    }

                    //either dob or age is required. if dob is null, the Age field will be showing. so show the validation error on that
                    if (isNullOrUndefined(child.DateOfBirth) && Number(child.Age) < 0) {
                        invalidFields.push(`ChildrenDetails[${index}].Age`);
                    }

                    if (stringIsNullOrEmpty(child.Gender)) {
                        invalidFields.push(`ChildrenDetails[${index}].Gender`);
                    }

                    if (invalidFields.length > 0)
                        validationResults.push(validationHelper.required(messages.required, invalidFields));

                    if (changes.ChildrenDetails !== undefined && Array.isArray(changes.ChildrenDetails)) {
                        const validationResult = validationHelper.ok();
                        const wasChild = changes.ChildrenDetails.find(obj => '' + obj.ID === child.ID);
                        if (wasChild) {
                            const familyChanges = [];
                            ChildFieldVerboseNames.forEach(element => {
                                const original = wasChild[element.Field];
                                const change = child[element.Field];
                                if (isDefinedAndDifferent(original, change)) {
                                    familyChanges.push(1);
                                }
                            });
                            if (familyChanges.length > 0) {
                                validationResult.requirement = requirement.optional;
                                validationResult.message = '';
                            }
                        } else {
                            validationResult.requirement = requirement.optional;
                            validationResult.message = '';
                        }
                        validationResults.push(validationResult);
                    }
                });

                return joinValidationResults(validationResults);
            },
            'Parent1.Type': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.Type';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent1.FirstName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.FirstName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent1.MiddleName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.MiddleName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent1.SurnameAtBirth': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.SurnameAtBirth';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent1.Surname': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.Surname';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent1.Occupation': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent1.Occupation';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent2.Type': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.Type';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent2.FirstName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.FirstName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent2.MiddleName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.MiddleName';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent2.SurnameAtBirth': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.SurnameAtBirth';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent2.Surname': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.Surname';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Parent2.Occupation': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('FamilyChangesFromBDM') || {};
                const fieldName = 'Parent2.Occupation';
                if (isDefinedAndDifferent(changes[fieldName], getField(fieldName))) {
                    return validationHelper.optional('Family entered: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            }
        }
    }
};
